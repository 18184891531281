export function getDestinationAddress(tripDataAddress, epcrData) {
  if (
    epcrData &&
    (epcrData.daddr ||
      epcrData.daddr2 ||
      epcrData.dcity ||
      epcrData.dst ||
      epcrData.dzip)
  ) {
    return {
      address1: epcrData.daddr,
      address2: epcrData.daddr2,
      city: epcrData.dcity,
      state: epcrData.dst,
      zip: epcrData.dzip,
    };
  }
  return tripDataAddress;
}
