import { stripEmptyValues } from 'src/utils/stripEmptyValues';
import { getPreferredValue } from './getPreferredValue';
import { getRunNumber } from './getRunNumber';

export function getFullInfo(appVisit) {
  var tripData = appVisit?.data;
  var epcrTripData = appVisit?.epcrJsonData?.trip;

  return stripEmptyValues([
    {
      label: 'Service Date',
      value: getPreferredValue(
        epcrTripData?.tradate,
        appVisit.serviceDateBegin
      ),
    },
    {
      label: 'Trip Id',
      value: appVisit.visitNumber,
    },
    {
      label: 'Level of Care',
      value: epcrTripData?.levelOfCare,
    },
    {
      label: 'Call Taken By',
      value: getPreferredValue(epcrTripData?.takenby, tripData?.takenBy),
    },
    {
      label: 'Source',
      value: epcrTripData ? 'PCR' : 'BILLING',
    },
    {
      label: 'Company',
      value: tripData?.company,
    },
    {
      label: 'Run #',
      value: getRunNumber(
        tripData?.runNumber,
        epcrTripData?.runNumber,
        tripData?.tdate
      ),
    },
    {
      label: 'Incident #',
      value: getPreferredValue(
        epcrTripData?.incidentNumber1,
        tripData?.incidentNumber
      ),
    },
    {
      label: 'Pick-up Time',
      value: tripData?.putime,
    },
    {
      label: 'Drop-off Time',
      value: getPreferredValue(epcrTripData?.atdtime, tripData?.atdtime),
    },
    {
      label: 'Vehicle',
      value: getPreferredValue(epcrTripData?.veh, tripData?.veh),
    },
  ]);
}
