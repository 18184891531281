<template>
  <span class="is-small">
    <span class="field" style="color: #0A59A7;cursor: pointer" @click="doThisStuff">
      {{ value }}
      <i v-if="validatedPickupAddress && validatedDestinationAddress" class="fas fa-map"></i>
    </span>
    <b-modal :id="uniqueTripId" size="xl" title="Driving Directions" hide-footer>
      <div class="widget-section">
        <div slot="content" style="height:100%; ">
          <div id="right-panel"></div>
          <div id="map"></div>
          <button class="map-print" @click="printDirections()">Print</button>
        </div>
      </div>
    </b-modal>
    <b-modal :id="uniqueAirTripId" size="xl" title="Flight Directions" hide-footer>
      <div class="widget-section">
        <div class="widget-section">
          <div slot="content" style="height:100%; ">
            <div id="map"></div>
          </div>
        </div>
      </div>
    </b-modal>
  </span>
</template>

<script>
  import gmapsInit from 'src/gmaps';
  export default {
    props: {
      //A unique ID is needed so if multiple of these are created
      // we don't get many overlapping modals
      sourceId: {
        type: String,
        required: true,
      },
      value: null,
      isAir: null,
      validatedPickupAddress: {},
      validatedDestinationAddress: {},
    },
    components: {},
    data() {
      return {
        uniqueTripId: 'trip-directions-modal'+ this.sourceId,
        uniqueAirTripId: 'trip-directions-flight' + this.sourceId, 
      };
    },
    methods: {
      async doThisStuff() {
        var start = {
          lat: this.validatedPickupAddress.latitude,
          lng: this.validatedPickupAddress.longitude,
        };
        var end = {
          lat: this.validatedDestinationAddress.latitude,
          lng: this.validatedDestinationAddress.longitude,
        };
        if (this.isAir) {
          this.$bvModal.show(this.uniqueAirTripId);
          await this.loadDirectionsFlight(start, end);

        } else {
          this.$bvModal.show(this.uniqueTripId);
          await this.loadDirections(start, end);
        }
      },
      printDirections() {
        var mywindow = window.open(
          `../directions?start=${JSON.stringify(this.start)}&end=${JSON.stringify(
          this.end
        )}`,
          'Print'
        );
        mywindow.focus();
      },
      async loadDirectionsFlight(start, end) {
        this.start = start;
        this.end = end;
        const google = await gmapsInit();
        var map = new google.maps.Map(document.getElementById('map'), {
          zoom: 2,
          center: start,
        });

        // var directionsService = new google.maps.DirectionsService()

        var lineCoordinates = [start, end];
        var linePath = new google.maps.Polyline({
          path: lineCoordinates,
          geodesic: true,
          strokeColor: '#add8e6',
        });

        linePath.setMap(map);

        var bounds = new google.maps.LatLngBounds();
        // eslint-disable-next-line
        var marker1 = new google.maps.Marker({
          position: start,
          map: map,
          title: 'Pickup',
        });
        bounds.extend(marker1.position);
        // eslint-disable-next-line
        var marker2 = new google.maps.Marker({
          position: end,
          map: map,
          title: 'Dropoff',
        });
        bounds.extend(marker2.position);

        map.fitBounds(bounds);
      },
      async loadDirections(start, end) {
        console.log('loaddirections', start, end, document.getElementById('map'))
        var routesResponses = [];
        var routes = [];
        this.start = start;
        this.end = end;
        const google = await gmapsInit();
        var map = new google.maps.Map(document.getElementById('map'), {
          zoom: 2,
          center: {
            lat: 39.612742,
            lng: -84.15901500000001
          },
        });
        var directionsDisplay = new google.maps.DirectionsRenderer();
        var directionsService = new google.maps.DirectionsService();

        directionsDisplay.setMap(map);
        var rightPanelDirectionList = document.getElementById('right-panel');
        while (rightPanelDirectionList.hasChildNodes()) {
          rightPanelDirectionList.removeChild(rightPanelDirectionList.lastChild);
        }
        directionsDisplay.setPanel(document.getElementById('right-panel'));
        directionsService.route({
            origin: start,
            destination: end,
            travelMode: 'DRIVING',
            provideRouteAlternatives: true,
          },
          function (response, status) {
            if (status === 'OK') {
              routesResponses.push(response);
            } else {
              window.alert('Directions request failed due to ' + status);
            }
            // Results analysis and drawing of routes
            var fastest = Number.MAX_VALUE;
            var shortest = Number.MAX_VALUE;

            routesResponses.forEach(function (res) {
              res.routes.forEach(function (rou, index) {
                console.log(
                  'distance of route ' + index + ': ',
                  rou.legs[0].distance.value
                );
                console.log(
                  'duration of route ' + index + ': ',
                  rou.legs[0].duration.value
                );
                if (rou.legs[0].distance.value < shortest) {
                  shortest = rou.legs[0].distance.value;
                  routes.unshift(rou);
                } else {
                  routes.push(rou);
                }
                if (rou.legs[0].duration.value < fastest)
                  fastest = rou.legs[0].duration.value;
              });
              res.routes = routes;
            });
            console.log('shortest: ', shortest);
            console.log('fastest: ', fastest);
            directionsDisplay.setDirections(response);
          }
        );
      },

    },
  };

</script>
