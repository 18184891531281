import { SET_TRIP_DISTANCE } from 'src/store/actions/types';
export async function getGoogleDistance(
  pickupAddress,
  destinationAddress,
  crowFlight
) {
  //test that both addresses have latitude and longitude
  //Call and await the action to get the distance.
  if (
    pickupAddress &&
    destinationAddress &&
    pickupAddress.latitude &&
    pickupAddress.longitude &&
    destinationAddress.latitude &&
    destinationAddress.longitude
  ) {
    await this[SET_TRIP_DISTANCE]({
      pickupLatitude: pickupAddress.latitude,
      pickupLongitude: pickupAddress.longitude,
      destinationLatitude: destinationAddress.latitude,
      destinationLongitude: destinationAddress.longitude,
      asCrowFlies: crowFlight,
    });
  }
}
