export function getPickupAddress(tripDataAddress, epcrData) {
  if (
    epcrData &&
    (epcrData.puaddr ||
      epcrData.puaddr2 ||
      epcrData.pucity ||
      epcrData.pust ||
      epcrData.puzip)
  ) {
    return {
      address1: epcrData.puaddr,
      address2: epcrData.puaddr2,
      city: epcrData.pucity,
      state: epcrData.pust,
      zip: epcrData.puzip,
    };
  }
  return tripDataAddress;
}
