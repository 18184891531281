import { dateTimeStr } from 'src/utils/dateTimeStr';
import { isValidDate } from './isValidDate';

export function validateAndParse(dateVal) {
  var lowDate = new Date(1901, 1, 1);
  var date = new Date(dateVal);
  if (isValidDate(date) && date > lowDate) {
    return dateTimeStr(date);
  }
  return '';
}
