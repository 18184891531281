import { stripEmptyValues } from 'src/utils/stripEmptyValues';
import { validateAndParse } from './validateAndParse';
import { isNull } from './isNull';

export function getTripTimes(tripData, epcrTripData, epcrTripTimeData) {
  if (!tripData && !epcrTripData && !epcrTripTimeData) {
    return [];
  }
  return stripEmptyValues([
    {
      location: 'Injury Date',
      time: validateAndParse(
        epcrTripData?.injurydate + 'T' + epcrTripData?.injurytime
      ),
    },
    {
      location: 'PSAP',
      time: validateAndParse(
        epcrTripTimeData?.psaP_date + 'T' + epcrTripTimeData?.psaP_time
      ),
    },
    {
      location: 'Received',
      time: isNull(
        validateAndParse(epcrTripData?.calldate + 'T' + epcrTripData?.calltime),
        validateAndParse(tripData?.calldate + 'T' + tripData?.calltime)
      ),
    },
    {
      location: 'Dispatched',
      time: isNull(
        validateAndParse(epcrTripData?.dispdate + 'T' + epcrTripData?.disptime),
        validateAndParse(tripData?.dispdate + 'T' + tripData?.disptime)
      ),
    },
    {
      location: 'En Route',
      time: isNull(
        validateAndParse(epcrTripData?.enrdate + 'T' + epcrTripData?.enrtime),
        validateAndParse(tripData?.enrdate + 'T' + tripData?.enrtime)
      ),
    },
    {
      location: 'At Scene',
      time: isNull(
        validateAndParse(epcrTripData?.atsdate + 'T' + epcrTripData?.atstime),
        validateAndParse(tripData?.atsdate + 'T' + tripData?.atstime)
      ),
    },
    {
      location: 'At Patient',
      time: validateAndParse(
        epcrTripData?.atpsdate + 'T' + epcrTripData?.atpstime
      ),
    },
    {
      location: 'Trans of care',
      time: validateAndParse(
        epcrTripTimeData?.txcare_date + 'T' + epcrTripTimeData?.txcare_time
      ),
    },
    {
      location: 'Transport',
      time: isNull(
        validateAndParse(epcrTripData?.tradate + 'T' + epcrTripData?.tratime),
        validateAndParse(tripData?.tradate + 'T' + tripData?.tratime)
      ),
    },
    {
      location: 'At Dest',
      time: isNull(
        validateAndParse(epcrTripData?.atddate + 'T' + epcrTripData?.atdtime),
        validateAndParse(tripData?.atddate + 'T' + tripData?.atdtime)
      ),
    },
    {
      location: 'In Service',
      time: isNull(
        validateAndParse(epcrTripData?.avldate + 'T' + epcrTripData?.avltime),
        validateAndParse(tripData?.avldate + 'T' + tripData?.avltime)
      ),
    },
    {
      location: 'Cancel',
      time: isNull(
        validateAndParse(epcrTripData?.cxldate + 'T' + epcrTripData?.cxltime),
        validateAndParse(tripData?.cxldate + 'T' + tripData?.cxltime)
      ),
    },
    {
      location: 'At Base',
      time: validateAndParse(
        epcrTripData?.atstadate + 'T' + epcrTripData?.atstatime
      ),
    },
  ]);
}
