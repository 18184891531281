import { getPreferredValue } from './helpers/getPreferredValue';
import { getGoogleDistance } from './helpers/getGoogleDistance';
import { getPickupAddress } from './helpers/getPickupAddress';
import { getDestinationAddress } from './helpers/getDestinationAddress';
import { getMileageObj } from './helpers/getMileageObj';
import { stripEmptyValues } from 'src/utils/stripEmptyValues';
import { scrubData } from './helpers/scrubData';
import { getTripTimes } from './TripTimes/getTripTimes';
import { getFullInfo } from './helpers/getFullInfo';

export async function data() {
  //Inside the object we want tripTime data for the triptimes modal
  //and an array of all objects to display, maybe another of just
  //the informatoin to display initially in the widget
  var dataObject = {};
  var appVisit = this.applicantVisits[0];

  //Full info will be displayed in a modal if the user wants ALL
  //the info
  var fullInfoArray = [];
  //The compact info will be displayed on the widget at all times
  var compactInfoArray = [];
  if (appVisit) {
    var tripData = appVisit.data;
    var epcrTripData = appVisit?.epcrJsonData?.trip;
    //Get information for the getDistance api call
    var validatedPickupAddress = getPreferredValue(
      appVisit.epcrPickupValidatedAddress,
      appVisit.pickupValidatedAddress
    );
    var validatedDestinationAddress = getPreferredValue(
      appVisit.epcrDestinationValidatedAddress,
      appVisit.destinationValidatedAddress
    );
    var crowFlies = getPreferredValue(
      epcrTripData?.vehicleFlight,
      tripData?.vehicleFlight
    );

    //This sets appVisit.tripDistance if the passed addresses lattitude/longitude exists
    await getGoogleDistance.call(
      this,
      validatedPickupAddress,
      validatedDestinationAddress,
      crowFlies
    );

    var mileageObj = getMileageObj(
      getPreferredValue(epcrTripData?.odomats, tripData?.odomats),
      getPreferredValue(epcrTripData?.odomatd, tripData?.odomatd),
      tripData?.miles,
      appVisit.tripDistance?.distance
    );

    //The ordering in this array is how they will be displayed on the widget
    var displayData = stripEmptyValues([
      {
        label: 'Pickup',
        value: getPreferredValue(
          epcrTripData?.pufac_name,
          tripData?.pufac_name
        ),
        address: getPickupAddress(tripData?.pickupAddress, epcrTripData),
        validatedAddress: validatedPickupAddress,
        isAddress: true,
      },
      {
        label: 'Dropoff',
        value: getPreferredValue(epcrTripData?.dfac_name, tripData?.dfac_name),
        address: getDestinationAddress(
          tripData?.destinationAddress,
          epcrTripData
        ),
        validatedAddress: validatedDestinationAddress,
        isAddress: true,
      },
      {
        label: 'Call Type',
        value: getPreferredValue(epcrTripData?.calltype, tripData?.callType),
      },
      {
        label: 'Outcome',
        value: epcrTripData?.outcome,
      },
      {
        label: 'Response Priority',
        value: getPreferredValue(epcrTripData?.priority, tripData?.priority),
      },
      {
        label: 'Transport Priority',
        value: getPreferredValue(
          epcrTripData?.transpriority,
          tripData?.transpriority
        ),
      },
      {
        label: 'Destination Reason',
        value: epcrTripData?.tripDestinationReason,
      },
      {
        label: 'Nature of Call',
        value: getPreferredValue(
          epcrTripData?.natureofcall,
          tripData?.natureId
        ),
      },
      {
        label: 'Receiving Facility Patient ID',
        value: epcrTripData?.recvFacilityPatientId,
      },

      {
        label: 'Odometer',
        value: mileageObj.odometer,
      },
      {
        label: 'Loaded (Billing)',
        value: scrubData(tripData?.miles, tripData?.miles + ' mi'),
      },
      {
        label: 'Vehicle #',
        value: getPreferredValue(epcrTripData?.veh),
      },
      {
        label: 'Mileage',
        value: scrubData(
          mileageObj.googleDistance,
          (crowFlies ? 'Google Distance - Air ' : 'Google Distance ') +
            mileageObj.googleDistance
        ),
        validatedPickupAddress: validatedPickupAddress,
        validatedDestinationAddress: validatedDestinationAddress,
        directionsModal: true,
        isAir: crowFlies, //true/false
      },
    ]);

    if (mileageObj.mileageDiscrepancy) {
      dataObject.errors = mileageObj.mileageDiscrepancy;
    }
    compactInfoArray = [...displayData];
    fullInfoArray = [...displayData, ...getFullInfo(appVisit)];
    dataObject.tripTimes = getTripTimes(
      tripData,
      epcrTripData,
      appVisit?.epcrJsonData?.tripTimes
    );
  }
  dataObject.displayData = compactInfoArray;
  dataObject.fullData = fullInfoArray;

  return dataObject;
}
