import { hasMileDiscrepancy } from './hasMileDiscrepancy';
const MILE_THRESHOLD = 1.5;

/**
 * Takes in the odemeter and mile values and
 * calculates all the values needed for displaying
 * @param {*} odomatStart
 * @param {*} odomatDestination
 * @param {*} miles
 * @param {*} googleDistance
 * @returns
 */
export function getMileageObj(
  odomatStart,
  odomatDestination,
  miles,
  googleDistance
) {
  var googleMiles = undefined;
  var odometerStr = '';
  var discrepancy = '';
  var calculatedMileage = undefined;

  if (googleDistance) {
    googleMiles = Number(googleDistance.replace('mi', ''));
  }

  if (odomatStart && odomatDestination) {
    calculatedMileage = (odomatDestination - odomatStart) / 10;
    odometerStr =
      odomatStart / 10 +
      ' - ' +
      odomatDestination / 10 +
      ' (' +
      calculatedMileage +
      ' mi)';
  }

  //Check if there are any discrepencies between the miles we
  //have and the miles we acquired
  //from google/calculated
  if (miles) {
    if (
      calculatedMileage &&
      hasMileDiscrepancy(calculatedMileage, miles, MILE_THRESHOLD)
    ) {
      discrepancy =
        'There is a large discrepancy between the loaded miles and the odometer mileage';
    }
    if (googleMiles && hasMileDiscrepancy(googleMiles, miles, MILE_THRESHOLD)) {
      if (!discrepancy) {
        discrepancy =
          'There is a large discrepancy between the loaded miles and the miles provided by Google Maps';
      } else {
        discrepancy +=
          ', the loaded miles and the miles provided by Google Maps';
      }
    }
  }

  if (calculatedMileage) {
    if (
      googleMiles &&
      hasMileDiscrepancy(googleMiles, calculatedMileage, MILE_THRESHOLD)
    ) {
      if (!discrepancy) {
        discrepancy =
          'There is a large discrepancy between the odometer readings and the miles provided by Google Maps';
      } else {
        discrepancy +=
          ', the odometer readings and the miles provided by Google Maps';
      }
    }
  }

  if (discrepancy) {
    discrepancy += '.  Please review.';
  }
  return {
    odometer: odometerStr,
    mileageDiscrepancy: discrepancy,
    googleDistance: googleDistance,
  };
}
