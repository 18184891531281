import { getPreferredValue } from './getPreferredValue';

export function getRunNumber(runNumber, epcrRunNumber, date) {
  var displayRunNumber = runNumber;
  if (runNumber && date) {
    displayRunNumber = date.substring(2, 4) + '-' + runNumber.toString();
  }

  return getPreferredValue(epcrRunNumber, displayRunNumber);
}
