import ErrorBanner from 'src/components/FullPatientData/PatientWidgetBody/ErrorBanner/ErrorBanner';
import AddressControl from 'src/components/FullPatientData/PatientWidgetBody/AddressControlUpdated/AddressControlUpdated';
import DirectionsControl from 'src/components/controls/DirectionsControl';

export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    source: {
      type: String,
      required: true,
    },
    errors: String,
  },
  components: {
    AddressControl,
    DirectionsControl,
    ErrorBanner,
  },
};
