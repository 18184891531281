/**
 * If the preferred value exists that is returned,
 * otherwise we return the secondary value.
 * @param {*} preferred
 * @param {*} secondary
 * @returns
 */
export function getPreferredValue(preferred, secondary) {
  return preferred ? preferred : secondary;
}
