import Widget from 'src/components/FullPatientData/PatientWidgetBody/Widget/Widget';
import TripDataDisplay from 'src/components/FullPatientData/PatientWidgetBody/TripWidget/TripDataDisplay/TripDataDisplay';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faAmbulance } from '@fortawesome/free-solid-svg-icons';
import { mapGetters, mapState, mapActions } from 'vuex';
import { permissionTypes } from 'src/constants/permissions';
import { widgets } from 'src/constants/widgets';
import { SET_TRIP_DISTANCE } from 'src/store/actions/types';
import { data } from './computed/data';

// icon of the widget header
library.add(faAmbulance);

export default {
  props: {
    loadedCallback: {
      type: Function,
      required: true,
    },
    dragStartHandler: {
      type: Function,
      required: true,
    },
  },
  components: {
    Widget,
    TripDataDisplay,
  },
  computed: {
    ...mapGetters(['applicant', 'hasPermission']),
    ...mapState({
      applicantVisits: state => state.applicant.visits.data,
      loading: state => !state.applicant.visits.retrieved,
    }),
    // permission
    canView() {
      return this.hasPermission(permissionTypes.canViewApplicant);
    },
    canViewPCR() {
      return this.hasPermission(permissionTypes.canViewPCR);
    },
    canViewTripDetails() {
      return this.hasPermission(permissionTypes.viewTripDetails);
    },
    widgetType() {
      return widgets.trip.type;
    },
  },
  watch: {
    // TODO: refactor this into the widget component,
    // when ALL widgets actually use that component, to be DRY
    loading: {
      immediate: true,
      handler(current, previous) {
        // make sure the DOM is updated before dispatching this action
        // because we need the widget to have its full dimensions after
        // loading the data and rendering them...
        this.$nextTick(function() {
          if (current !== previous) {
            this.loadedCallback(!current);
          }
        });
      },
    },
  },
  methods: {
    ...mapActions([SET_TRIP_DISTANCE]),
    hasData() {
      if (this.tripData.displayData?.length) {
        return (
          this.tripData.displayData.filter(object => object.value !== undefined)
            .length > 0
        );
      }
      return false;
    },
    async launchCefSharpPCR() {
      console.log('launchCefSharpPCR');
      try {
        // eslint-disable-next-line
        await CefSharp.BindObjectAsync('cefSharpBoundAsyncPcr', 'bound');
        // eslint-disable-next-line
        cefSharpBoundAsyncPcr.showMessage();
      } catch (error) {
        console.log(
          'CefSharpLaunch Error: (most likely not running withn RN->PL app)',
          error
        );
      }
    },
  },
  data() {
    return {
      tripData: {},
      loadingInfo: true,
    };
  },
  async created() {
    this.tripData = await data.call(this);
    this.loadingInfo = false;
  },
  beforeDestroy() {
    this.loadedCallback(false);
  },
};
